import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { ParallaxProvider } from 'react-scroll-parallax'

import ThemeProvider from './Theme/ThemeProvider'
import TopBar from './TopBar'
import WorkSubNav from './WorkSubNav'
import '../styles/app.scss'

const Layout = ({ children, location }) => {
  return (
    <ParallaxProvider>
      <ThemeProvider>
        <div>
          <Helmet titleTemplate="%s – belbiy">
            <html lang="en" />
            <meta
              name="description"
              content="Eugene Bannykh personal website."
            />
          </Helmet>
          <TopBar />
          <main>{children}</main>
          <WorkSubNav location={location} />
        </div>
      </ThemeProvider>
    </ParallaxProvider>
  )
}
//
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
