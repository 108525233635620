import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import FocusTrap from 'focus-trap-react'
import Link, { navigate } from 'gatsby-link'

import styles from './work-subnav.module.scss'
import WorkList from '../WorkList'
import { withTheme } from '../Theme/withTheme'

// TODO: refactor prev/next link
const PreviousLink = ({ item }) => {
  if (item) {
    return (
      <div className={styles.prevButtonWrap}>
        <Link to={item.path}>
          <i className={styles.direction}>
            &lsaquo; <i className={styles.key}>alt</i>
          </i>
          <span className={styles.title}>{item.title}</span>
        </Link>
      </div>
    )
  } else {
    return <div className={styles.prevButtonWrap} />
  }
}

const NextLink = ({ item }) => {
  if (item) {
    return (
      <div className={styles.nextButtonWrap}>
        <Link to={item.path}>
          <span className={styles.title}>{item.title}</span>
          <i className={styles.direction}>
            <i className={styles.key}>alt</i> &rsaquo;
          </i>
        </Link>
      </div>
    )
  } else {
    return <div className={styles.nextButtonWrap} />
  }
}

const WorkSubNav = props => {
  const { location } = props
  const path = location ? location.pathname : null
  const workWidth = 250
  const [isOpen, setOpen] = useState(false)
  const outerShaftRef = useRef()
  const wrapperRef = useRef()
  const triggerRef = useRef()
  const closeButtonRef = useRef()
  const ariaLabel = 'Works'

  const query = useStaticQuery(
    graphql`
      query WorksSubnavQuery {
        allJavascriptFrontmatter(
          sort: { fields: [frontmatter___date], order: DESC }
          filter: { frontmatter: { published: { ne: false } } }
        ) {
          edges {
            node {
              ...WorkData
            }
          }
        }
      }
    `
  )
  const works = query
  const worksItems = works.allJavascriptFrontmatter.edges

  let previous, next, current

  worksItems.map((work, index) => {
    if (work.node.frontmatter.path === path) {
      previous = worksItems[index - 1]
        ? worksItems[index - 1].node.frontmatter
        : null
      next = worksItems[index + 1]
        ? worksItems[index + 1].node.frontmatter
        : null
      current = index
    }
    return index
  })

  const handleClick = event => {
    if (wrapperRef.current.contains(event.target)) {
      // Clicked inside
      return
    }
    toggleDialog(false)
  }

  const centerShaftOnCurrentWork = () => {
    outerShaftRef.current.scrollLeft =
    current * workWidth +
    workWidth / 2 -
    outerShaftRef.current.offsetWidth / 2
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress, false)
    document.addEventListener('mousedown', handleClick)

    if (outerShaftRef.current) {
      // center the shaft to a current work
      centerShaftOnCurrentWork();
    }

    return () => {
      document.removeEventListener('keydown', handleKeyPress, false)
      document.removeEventListener('mousedown', handleClick)
    }
  })

  

  const handleKeyPress = event => {
    if (event.keyCode === 27) {
      // ESC
      toggleDialog(false)
    }

    if (event.altKey || event.ctrlKey) {
      // alt press
      switch (
        event.keyCode ? event.keyCode : event.which ? event.which : null
      ) {
        case 0x25: // left
          if (previous) {
            navigate(previous.path)
          }
          break
        case 0x27: // right
          if (next) {
            navigate(next.path)
          }
          break
        case 0x26: //up
          event.preventDefault()
          //setOpen(true)
          toggleDialog(true)
          break
        case 0x28: //down
          event.preventDefault()
          toggleDialog(false)
          //setOpen(false)
          break
        default:
        //
      }
    }
  }

  const handleMenuClick = event => {
    //setOpen()
    toggleDialog(!isOpen)
  }

  const handleCloseClick = event => {
    toggleDialog(false)
  }

  const toggleDialog = isOpen => {
    // Check if we're opening or closing
    if (isOpen) {
      // Set focus on close button
      closeButtonRef.current.focus()
    } else {
      triggerRef.current.focus()
    }

    setOpen(isOpen)
  }

  const backgroundColor = props.themeContext.background

  const parentClass = [
    styles.workSubNav,
    isOpen ? styles.active : null,
    props.themeContext.isDark ? styles.dark : null,
  ].join(' ')

  // Assign gradient manually if page has a backgroun color
  const menuStyle = backgroundColor
    ? {
        background:
          'linear-gradient(to bottom, ' +
          backgroundColor +
          'cc, ' +
          backgroundColor +
          ' 50% )',
      }
    : null

  return (
    <aside className={parentClass} ref={wrapperRef}>
      <FocusTrap
        active={isOpen}
        focusTrapOptions={{
          allowOutsideClick: true,
          preventScroll: true
        }}
        > 
        <dialog
          open={isOpen}
          className={styles.workList}
          aria-modal={true}
          aria-hidden={!isOpen}
          aria-label={ariaLabel}
        >
          <section ref={outerShaftRef} className={styles.worksOuterShaft}>
            <div
              className={styles.worksInnerShaft}
              style={{
                width: workWidth * worksItems.length,
              }}
            >
              <WorkList works={works} />
            </div>
          </section>
          <button
            className={styles.close}
            onClick={handleCloseClick}
            ref={closeButtonRef}
          >
            &times;
          </button>
        </dialog>
      </FocusTrap>

      <menu className={styles.adjacedWorksMenu} style={menuStyle}>
        <PreviousLink item={previous} />

        <button
          aria-pressed={isOpen}
          onClick={handleMenuClick}
          className={styles.menuButton}
          ref={triggerRef}
        >
          <span className={styles.menuIcon}>&hellip;</span>
          <span className={styles.menuText}>Works</span>
        </button>

        <NextLink item={next} />
      </menu>
    </aside>
  )
}

WorkSubNav.propTypes = {
  location: PropTypes.object.isRequired,
  themeContext: PropTypes.object.isRequired,
}

export default withTheme(WorkSubNav)
