import React from 'react'
import { Link } from 'gatsby'

import { withTheme } from '../Theme/withTheme'
import styles from './logo.module.scss'
import imageLogo from '../../i/hi.svg'

const Logo = props => {
  const wrapperClassName =
    styles.logo + (props.themeContext.isDark ? ' ' + styles.dark : '')

  return (
    <Link to="/" className={wrapperClassName} activeClassName={styles.active}>
      <img src={imageLogo} alt="Home page" /> <span>Eugene Bannykh</span>
    </Link>
  )
}

export default withTheme(Logo)
