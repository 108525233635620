import React from 'react'
import styles from './topbar.module.scss'
import Logo from '../Logo'
import MainNav from '../MainNav'

const TopBar = () => (
  <header className={styles.topbar} role="banner">
    <Logo />
    <MainNav />
  </header>
)

export default TopBar
