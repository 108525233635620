import React from 'react'
import styles from './main-nav.module.scss'
import Menu from '../Menu'
import { withTheme } from '../Theme/withTheme'

const mainMenuItems = [
  {
    title: 'Works',
    href: '/works/',
  },
  {
    title: 'Skills',
    href: '/services/',
  } /*
  {
    title: 'Blog',
    href: '/bg/',
  },*/,
  {
    title: 'Contact',
    href: '/contact/',
  },
]

const MainNav = props => {
  const navClassName =
    styles.mainNav + (props.themeContext.isDark ? ' ' + styles.dark : '')

  return (
    <Menu
      className={navClassName}
      items={mainMenuItems}
      itemClassName={styles.navItem}
      itemActiveClassName={styles.active}
      role="menubar"
    />
  )
}

export default withTheme(MainNav)
