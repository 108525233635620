import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { withTheme } from '../Theme/withTheme'
import styles from './menu.module.scss'

const MenuItem = props => {
  const { className, activeClassName, parentClassName, ...itemProps } = props

  const itemClassName = className ? className : styles.item
  const itemActiveClassName = activeClassName ? activeClassName : styles.active
  const itemParentClassName = parentClassName ? parentClassName : styles.parent

  const isPartiallyActive = ({ isPartiallyCurrent, isCurrent }) => {
    var c = isPartiallyCurrent ? itemParentClassName + ' ' : ''
    c += isCurrent ? itemActiveClassName : ' '

    return { className: c + ' ' + itemClassName }
  }

  return (
    <Link role="menuitem" getProps={isPartiallyActive} {...itemProps}>
      <span>{props.children}</span>
    </Link>
  )
}

const Menu = props => {
  const {
    className,
    items,
    itemClassName,
    itemActiveClassName,
    itemParentClassName,
    themeContext,
    ...menuProps
  } = props

  const menuClassName = [
    className ? className : styles.menu,
    themeContext.isDark ? styles.dark : null
  ].join(' ')

  return (
    <menu className={menuClassName} {...menuProps}>
      {items.map((item, index) => (
        <MenuItem
          key={index}
          to={item.href}
          className={itemClassName}
          activeClassName={itemActiveClassName}
          parentClassName={itemParentClassName}
        >
          {item.title}
        </MenuItem>
      ))}
    </menu>
  )
}

Menu.propTypes = {
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  itemActiveClassName: PropTypes.string,
  itemParentClassName: PropTypes.string,
}

MenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  parentClassName: PropTypes.string,
}

export default withTheme(Menu)
