import React, { useState } from 'react'
import { ThemeContext } from './ThemeContext'

const ThemeProvider = props => {
  const { children } = props

  const [isDark, setTheme] = useState(false)
  const [background, setBackground] = useState(null)

  return (
    <ThemeContext.Provider
      value={{
        themeContext: {
          setTheme,
          setBackground,
          isDark,
          background,
        },
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
